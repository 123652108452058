import React, { useMemo, useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import StockList from '../../components/StockListComponents/StockList';
import WarehouseFilter from '../../components/StockListComponents/WarehouseFilter';
import Chip from '@material-ui/core/Chip';
import FilterListIcon from '@material-ui/icons/FilterList';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import NavigationIcon from '@material-ui/icons/Navigation';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import useDataService from '../../hooks/useDataService';
import ReviewStockImport from '../../components/ReviewStockImport/ReviewStockImport';
import BulkOfferImportDialog from '../../components/BulkOfferImportDialog/BulkOfferImportDialog';
import DailyReportDialog from '../../components/DailyReportDialog/DailyReportDialog';
import LoadingDialog from '../../components/LoadingDialog/LoadingDialog';
import apiToUrlMap from '../../ApiMapping';
import Progress from '../../components/Progress/Progress';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Popper from '@material-ui/core/Popper';
import Typography from '@material-ui/core/Typography';
import Pagination from '../../components/AtomComponents/Pagination';
import './Stock.scss';
import { I18nContext } from '../../I18n';
import StockListTutorialDialog from '../../components/StockListTutorialDialog/StockListTutorialDialog';
import { PrimaryIconButton, TertiaryButton } from '../../components/AtomComponents';
import PrimaryBadge from '../../components/AtomComponents/PrimaryBadge';
import PrimaryCheckbox from '../../components/AtomComponents/PrimaryCheckbox';
import { eMessageType } from '../../types/IMessageType';
import {
  FilterInUseLevelType,
  FilterInUserType,
  gradeFilterGroups,
  KeysOfStockListSettings,
  PXNSettings,
  StockListSettings,
} from '../../types/StockListTypes';
import { defaultParticipant } from '../../_lib/constant';
import StockListFilterItems from '../../components/StockListComponents/StockListFilterItems';
import {
  generateGradeGroup,
  getCountOfOrdersExpiring,
  getExpiringOffersCount,
  getKeyTitle,
  specialPriceCondition,
} from '../../components/StockListComponents/StockListUtils';
import { isEmpty, sanitizeHTML } from '../../_lib/util';
import GradePopup from '../../components/GradePopup/GradePopup';
import { checkNullOrUndefined, getAttrLabel } from '../../_lib/lib';
import { stocklistViewed } from '../../redux/actions/analyticsEventActions';
import { Info, Star } from '@material-ui/icons';
import {
  ISnackBarWithActionPropsRef,
  SnackBarWithActionProps,
} from '../../components/OrdersComponents/SnackBarWithActionProps';
import { IGetShippingPaymentDetails } from '../../types/IBillingShippingTypes';
import { canBuyOnlineFn, isViewOnlyCustomer } from '../../_lib/tagUtil';
import { getConfigFromLocalStorage } from '../../hooks/useFetchConfig';
import AccountSetupPopup from '../../components/AccountSetup/AccountSetupPopup';
import CategoryFilter from '../../components/StockListComponents/CategoryFilter';
import { isGroupStockLot } from '../../components/StockListComponents/StockLot/stockLotUtils';
import {
  stockAlertsSet,
  stockLotSet,
  stockOffersUpdate,
} from '../../redux/actions/stockGroupActions';
const WarehouseGroupDescriptionDialog = React.lazy(
  () => import('../../components/StockListComponents/WarehouseGroupDescriptionDialog')
);

function Stock(props: any) {
  const I18n = React.useContext(I18nContext);
  const { exportData, fileUpload, fetchUrl, openSnackBar } = useDataService();
  const dispatch = useDispatch();

  const {
    stockListSettings,
    pxnWarehousesObj,
    authorities,
    configState,
    enabledTags,
    setupCompleted,
    cartNumber,
    alertsBadgeNumber,
  } = props;
  const pxnSettings: PXNSettings = props.pxnSettings;
  const curTenantSettings = stockListSettings && stockListSettings[pxnSettings.pxnrParticipantId];

  const formattedTimeStamp = useRef('');

  const importDialogRef = useRef<any>(null);
  const topOfResultsRef = useRef<any>();
  const isReloadRef = useRef<boolean>(false);
  const snackBarRef = useRef<ISnackBarWithActionPropsRef>();
  const awaitingOffersSnackbarRef = useRef<ISnackBarWithActionPropsRef>();
  const expiringOnHoldOrdersSnackbarRef = useRef<ISnackBarWithActionPropsRef>();
  const expiringAwaitingPaymentOrdersRef = useRef<ISnackBarWithActionPropsRef>();

  React.useEffect(() => {
    const config = getConfigFromLocalStorage();
    document.title = String(config.siteTitle) + ' - ' + (I18n?.stock?.i18n_value || 'Stock');

    window.addEventListener('scroll', disableScrollTopButton);
    return () => {
      window.removeEventListener('scroll', disableScrollTopButton);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showHideFilter, setShowHideFilter]: any = useState(false);

  // const [open, setOpen] = useState(false);
  const [popupState, setPopupState] = useState({
    tutorial: false,
    dailyReport: false,
    import: false,
    export: false,
    grades: false,
    warehouseGroupDescription: false,
  });
  const [accountSetupPopup, setAccountSetupPopup] = useState(false);
  const [itemCounts, setItemCounts] = useState<{ [key: number]: number }>();
  const [selectedStockListSettings, setStockListSettings] = useState<StockListSettings>();
  const selectStockListSettings = (participantId: string) => {
    const settings =
      stockListSettings[participantId] || stockListSettings[Object.keys(stockListSettings)[0]];
    setStockListSettings({
      ...settings,
      pxParticipantId: `${participantId}`,
    });
  };
  const selectParticipant = async (participantId: string) => {
    if (!stockListParticipants) throw new Error('stock list data not available');
    // SET SETTINGS
    selectStockListSettings(participantId);

    // SET DATA
    await selectStockListResults(participantId);
  };
  const selectParticipantAndSystemState = async (participantId: string) => {
    if (pxnSettings.isPxnr) {
      // UPDATE URL
      const historyParams: any = {
        warehouseGroup: participantId,
      };
      queryParams(historyParams);
    }

    // RE RENDER UI
    setStockListR(null);
    setMenuDataState(null);
    setItemGroupKeysState(null);
    setLoadingState(0);
    setCurrentPage(1);

    await selectParticipant(participantId);
  };

  const [openDifferentView, setOpenDifferentView] = useState<null | string>(null);
  const [reviewImportData, setReviewImportData] = useState(null);
  const [isImportLoading, setIsImportingDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const [
    selectedPreferencesOptions,
    setSelectedPreferencesOptions,
  ] = useState<IGetShippingPaymentDetails | null>(null);

  const [stockListResults, setStockListR] = useState<any>(null);
  const [stockListParticipants, setStockListParticipants] = useState<{
    [keys: string]: Array<any>;
  } | null>(null);

  const setParticipantStockList = (participantStocks: any, participantAdded: string) => {
    setStockListParticipants(participantStocks);
    console.log(
      participantStocks,
      participantAdded,
      'participantAdded',
      pxnSettings.pxnrParticipantId,
      `${participantAdded}` !== `${pxnSettings.pxnrParticipantId}`,
      !participantStocks[pxnSettings.pxnrParticipantId]
    );

    if (
      `${participantAdded}` !== `${pxnSettings.pxnrParticipantId}` ||
      !participantStocks[pxnSettings.pxnrParticipantId]
    )
      return;
    const stockLotItems = participantStocks[pxnSettings.pxnrParticipantId].groups.reduce(
      (acc: any, group: any) => {
        if (group.isStockLotGroup) {
          group.items.forEach((item: any) => {
            acc[item.itemNumberWarehouse] = {
              lotLongDescription: item.lotLongDescription,
              lotItemInfoURL: item.lotItemInfoURL,
              lotShortDescription: item.lotShortDescription,
              group: group.groupId,
              warehouse: group.warehouse,
            };
          });
        }
        return acc;
      },
      {} as any
    );
    dispatch(stockLotSet(stockLotItems));
  };

  const [specialPriceFilter, setSpecialPricingFilter] = useState({});

  const groupsPerPage = 24;

  // SEARCH
  const history = useHistory();
  const [filtersInUseState, setFiltersInUseState] = React.useState<FilterInUserType>({
    _isFilterting: false,
    _isDataLoaded: false,
    Level0: {
      category: null,
      warehouseGroup: null,
      warehouse: null,
      includeOutOfStock: false,
      specialPrice: false,
    },
    Level1: {},
    Level2: {},
    Level3: {},
  });
  const [itemGroupKeysState, setItemGroupKeysState] = React.useState<any>(null);
  const [filteredItemsState, setFilteredItemsState] = React.useState<any>(null);
  const [pageDataState, setPageDataState] = React.useState<any>(null);
  const [menuDataState, setMenuDataState] = React.useState<any>(null);

  const [isExportDropdownOpen, setIsExportDrodownOpen] = useState<boolean>(false);
  const [exportRef, setExportRef] = useState<any>(null);
  const [exportFilteredItems, setExportFilteredItems] = useState<any>({});

  const [loadingState, setLoadingState] = React.useState<any>(0);
  const [currentPage, setCurrentPage] = React.useState(1);

  const [selecetedGrade, setSelectedGrade] = React.useState<string>();
  const [selectedWarehouseGroup, setSelectedWarehouseGroup] = React.useState<{
    participantId: string;
    type: 'info' | 'prompt';
    promptSuccessCb: any;
  }>();

  const paginateTheItems = (currentPage: number, groupsPerPage: number) => {
    setPageDataState(
      filteredItemsState.slice((currentPage - 1) * groupsPerPage, currentPage * groupsPerPage)
    );
  };

  const updateSelectedPreferencesOptions = async (
    updatedPreferences: IGetShippingPaymentDetails
  ) => {
    try {
      await fetchUrl('POST', apiToUrlMap.shippingBillingPreferencesSubmit, {
        body: updatedPreferences,
      });
      setSelectedPreferencesOptions(updatedPreferences);
    } catch (err: any) {
      console.error(err.message);
    }
  };

  const handleUpload = async (importFile: any) => {
    // file is present
    try {
      setIsImportingDialog(true);
      const response: any = await fileUpload(importFile, apiToUrlMap.stockListImport);
      setReviewImportData(response);
      setOpenDifferentView('Review Import');
      setPopupState({ ...popupState, import: false });
      setIsImportingDialog(false);
    } catch (error: any) {
      if (importDialogRef) importDialogRef.current.setImportError(error.message);
      setIsImportingDialog(false);
    }
  };

  const onDailyReportOk = async (e: any, isChecked: boolean) => {
    try {
      await fetchUrl('put', apiToUrlMap.stockDailyReport, {
        body: {
          stockReportNotification: isChecked,
        },
      });

      setPopupState({ ...popupState, dailyReport: false });
      openSnackBar(
        isChecked
          ? I18n?.youWillreceiveDailySpreadSheet?.i18n_value ||
              'You will receive a daily spreadsheet report on all new inventory availability and price drops'
          : I18n?.youWillNoLongerReceiveDailyReport?.i18n_value ||
              'You will No Longer receive a daily spreadsheet report on all new inventory availability and price drops',
        eMessageType.success
      );
    } catch (error) {
      console.error(error);
    }
  };

  const fetchStockItemCounts = async () => {
    try {
      const stockItemCounts = await fetchUrl('get', apiToUrlMap.pxnStockItemCounts, {});
      return stockItemCounts;
    } catch (error) {
      console.error(error);
    }
  };

  // PAGE CHANGE - handle pagination
  const currentPageChange = (event: any, value: any) => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    setCurrentPage(value);
    setLoadingState(2);
  };

  // HANDLECHANGE STEP 2
  React.useEffect(() => {
    if (filtersInUseState?._isFilterting) {
      const { filteredGroupItems, menuData, newFiltersInUseStateObj } = filterItems();
      setFilteredItemsState(filteredGroupItems);
      if (filtersInUseState?.Level0?.warehouseGroup) {
        setItemCounts((prev) => {
          if (!prev) return prev;
          const warehouseGroupCount = menuData?.warehouse
            ? Object.keys(menuData?.warehouse || {}).reduce(
                (total, curr) => (total += menuData?.warehouse?.[curr]._totals || 0),
                0
              )
            : prev[filtersInUseState.Level0.warehouseGroup];
          return {
            ...prev,
            [filtersInUseState.Level0.warehouseGroup]: warehouseGroupCount,
          };
        });
      }
      setMenuDataState(menuData);
      setFiltersInUseState({
        ...newFiltersInUseStateObj,
        _isDataLoaded: true,
      });
      //generatePagination();
      setLoadingState(2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersInUseState]);

  // FINAL - GENERAL STEP - Add data to page
  React.useEffect(() => {
    if (loadingState === 2) {
      paginateTheItems(currentPage, groupsPerPage);
      //setShowHideFilter(false);
      setLoadingState(99);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingState]);

  // HANDLECHANGE STEP 1
  const updateFiltersInUse = (
    isChecked: any,
    targetValue: any,
    filterLevel: any,
    filterName: any,
    isReset?: any
  ) => {
    const newFilterStateObj = onFiltersChange(
      filtersInUseState,
      isChecked,
      targetValue,
      filterLevel,
      filterName,
      isReset
    );
    setFiltersInUseState({
      ...newFilterStateObj,
      _isDataLoaded: true,
    });
  };

  const updateLevel0Filter = (
    level0: any,
    filterName: string,
    isChecked: any,
    targetValue: any,
    isReset?: boolean
  ) => {
    let filterValue = targetValue;

    if (filterName === 'includeOutOfStock' || filterName === 'specialPrice')
      filterValue = isChecked;
    if (filterName === 'warehouse') {
      const prevValue = level0.warehouse || '';
      filterValue = isChecked
        ? `<|${targetValue}|>` + prevValue
        : String(prevValue || '').replace(`<|${targetValue}|>`, '');
    }
    if (filterName === 'category') {
      level0.warehouse = null;
    }
    if (isReset) {
      level0.includeOutOfStock = false;
      level0.specialPrice = false;
      level0.warehouse = '';
      level0.warehouseGroup = selectedStockListSettings?.pxParticipantId || defaultParticipant;
      return level0;
    }
    if (filterName === 'initial') {
      level0.includeOutOfStock = false;
      level0.specialPrice = filtersInUseState.Level0.specialPrice;
      level0.warehouse = '';
      level0.category = targetValue;
      level0.warehouseGroup = selectedStockListSettings?.pxParticipantId || defaultParticipant;
      return level0;
    }
    return {
      ...level0,
      category:
        level0?.category ??
        getDefaultSelectedCategory(selectedStockListSettings as StockListSettings),
      [filterName]: filterValue,
    };
  };

  const onFiltersChange = (
    prevState: any,
    isChecked: any,
    targetValue: any,
    filterLevel: any,
    filterName: any,
    isReset?: any
  ) => {
    const change: any = Object.assign({}, prevState);

    if (filterLevel === 0) {
      change['Level0'] = updateLevel0Filter(
        change['Level0'],
        filterName,
        isChecked,
        targetValue,
        !!isReset
      );
      change[`Level1`] = {};
      change[`Level2`] = {};
      change[`Level3`] = {};
    } else if (filterName !== 'warehouse') {
      let prevValue = prevState[`Level${filterLevel}`][filterName] || '';

      change[`Level${filterLevel}`] = {
        ...change[`Level${filterLevel}`],
        [filterName]: isChecked
          ? `<|${targetValue}|>` + prevValue
          : String(prevValue || '').replace(`<|${targetValue}|>`, ''),
      };

      if (change[`Level${filterLevel}`][filterName] === '')
        delete change[`Level${filterLevel}`][filterName];

      if (Object.keys(change[`Level${filterLevel}`]).length === 0) {
        for (let i = filterLevel + 1; i <= 3; i++) {
          change[`Level${i}`] = {};
        }
      }
    }

    change._isFilterting = true;
    change._lastFilterApplied = filterName;

    setTimeout(() => {
      const historyParams: any = {};
      [0, 1, 2, 3].forEach((level: number) => {
        let hasFilters = Object.keys(change[`Level${level}`]);
        if (hasFilters.length) {
          hasFilters.forEach((filter: string) => {
            if (!pxnSettings.isPxnr && filter === 'warehouseGroup') return;
            const changeFilter = change[`Level${level}`][filter] || '';
            if (changeFilter && typeof changeFilter)
              historyParams[filter] =
                typeof changeFilter === 'boolean'
                  ? changeFilter
                  : String(changeFilter || '')
                      .replace(/\|><\|/g, ',')
                      .replace(/<\|/g, '')
                      .replace(/\|>/g, '');
          });
        }
      });
      queryParams(historyParams);
      getFilteredItems(historyParams);
    }, 0);

    return change;
  };

  const queryParams = (historyParams: any) => {
    const queryParamsValue = Object.keys(historyParams)
      .reduce((a: any, k: any) => {
        a.push(k + '=' + encodeURIComponent(historyParams[k]));
        return a;
      }, [])
      .join('&');
    history.push({
      search: queryParamsValue,
    });
  };

  // HANDLECHANGE -- Handle changes when we click on the filters
  const handleChange = (e: any, filterLevel: number, filterName: string, isReset?: boolean) => {
    // handle level 0 differently
    // Get value of the element

    if (filterName === 'category')
      setMenuDataState((prevState: any) => ({ ...prevState, noOfLevelsToBeDisplayed: 0 }));

    setCurrentPage(1);

    const isChecked = e.target.checked;
    const targetValue = e.target.value;

    setTimeout(() => {
      updateFiltersInUse(isChecked, targetValue, filterLevel, filterName, isReset);
    }, 0);
  };

  const dateFormat = (timeStampString: string) => {
    const newDateTimeString = new Date(timeStampString);
    return (
      (I18n?.lastUpdated?.i18n_value || 'Last updated') +
      `: ${newDateTimeString.toDateString()}, ${newDateTimeString.toLocaleTimeString()}`
    );
  };

  // STEP 3 - Make sure all filtered data is available before splittling data for current page num
  React.useEffect(() => {
    // Guarantee that everything is done in order to proceed
    if (loadingState === 1 && filteredItemsState && menuDataState) {
      // Go to FINAL STEP
      setLoadingState(2);
    }
  }, [loadingState, filteredItemsState, menuDataState]);

  const getGroupStock = () => {
    return new Promise(async (resolve: any) => {
      const stockGroups = await fetchUrl('get', apiToUrlMap.stocksGet, {});
      stockGroups.groups = Object.keys(stockGroups.groups).map((key: string) => {
        return {
          ...stockGroups.groups[key],
          warehouse: stockGroups.groups[key].wareHouse,
          isStockLotGroup: isGroupStockLot(stockGroups.groups[key]),
        };
      });
      resolve(stockGroups);
    });
  };

  const getPxnsStocks = async (participantId: number) => {
    try {
      const res = await fetchUrl('get', apiToUrlMap.pxnStockItems, {
        headers: { 'pxns-participant-id': participantId },
      });
      res.groups = Object.keys(res.groups).map((key: string) => {
        return {
          ...res.groups[key],
          warehouse: res.groups[key].wareHouse,
          items: res.groups[key].items.map((item: any) => {
            return {
              ...item,
              itemNumber: item.pxnItemNumber,
            };
          }),
          isStockLotGroup: isGroupStockLot(res.groups[key]),
        };
      });
      return res;
    } catch (error) {
      throw error;
    }
  };

  const selectStockListResults = async (participantId: string) => {
    if (!stockListParticipants) throw new Error('stocklist data is not available');
    let selectedParticipantData = stockListParticipants?.[participantId] as any;
    if (!(participantId in stockListParticipants)) {
      if (!pxnSettings.isPxnr || pxnWarehousesObj[participantId].isPxnr) {
        selectedParticipantData = await getGroupStock();
      } else if (pxnWarehousesObj[participantId].isPxns) {
        selectedParticipantData = await getPxnsStocks(+participantId);
      }
      setParticipantStockList(
        {
          ...stockListParticipants,
          [participantId]: selectedParticipantData,
        },
        participantId
      );
    }

    setStockListR(selectedParticipantData);
  };

  const setupStockList = async () => {
    // TODO: fetch participant data
    // if tenant is reseller then fetch participant data
    const stocklistResult: any = {};
    const participantId =
      new URLSearchParams(history.location.search).get('warehouseGroup') ||
      pxnSettings.pxnrParticipantId;

    const participant = pxnWarehousesObj?.[participantId];

    const [groupStocks, pxnsStocks, itemsCounts] = await Promise.all([
      (!pxnWarehousesObj || pxnWarehousesObj?.[pxnSettings.pxnrParticipantId]?.isPxnr) &&
        getGroupStock(),
      participant?.isPxns && !participant?.isPxnr && getPxnsStocks(+participantId),
      configState.networkParticipant && pxnSettings.isPxnr && fetchStockItemCounts(),
    ]);

    if (groupStocks) stocklistResult[pxnSettings.pxnrParticipantId] = groupStocks;
    if (pxnsStocks) stocklistResult[participantId] = pxnsStocks;
    setParticipantStockList(
      stocklistResult,
      !pxnWarehousesObj || pxnWarehousesObj?.[pxnSettings.pxnrParticipantId]?.isPxnr
        ? pxnSettings.pxnrParticipantId
        : participantId
    );

    const updatedItemCounts: { [key: number]: number } = {};
    if (stocklistResult && pxnSettings.pxnrParticipantId in stocklistResult) {
      const pxnrItems = (stocklistResult?.[pxnSettings.pxnrParticipantId] as any)?.groups;
      const pxnrItemCounts =
        Object.keys(pxnrItems || {}).reduce((total: any, groupKey: any) => {
          if (!pxnrItems[groupKey].minPrice || !pxnrItems[groupKey].quantityAvailable) return total;
          return (total += pxnrItems[groupKey].countOfItems);
        }, 0) ?? 0;
      updatedItemCounts[pxnSettings.pxnrParticipantId] = pxnrItemCounts;
    }
    if (configState.networkParticipant && pxnSettings.isPxnr && itemsCounts) {
      const stockItemCounts = itemsCounts;
      stockItemCounts?.forEach((stockItemCount: any) => {
        updatedItemCounts[stockItemCount.pxnsParticipantId] = stockItemCount.itemsCount;
      });
    }
    setItemCounts((prev) => {
      const updatedCounts = { ...prev };
      Object.keys(updatedItemCounts).forEach((key: string) => {
        if (+key in updatedCounts || !!updatedCounts[+key]) return;
        updatedCounts[+key] = updatedItemCounts[+key];
      });
      return { ...updatedCounts };
    });
    dispatch(stocklistViewed(stocklistResult[pxnSettings.pxnrParticipantId]));
  };

  React.useEffect(() => {
    if (
      stockListParticipants &&
      !selectedStockListSettings &&
      itemCounts &&
      !stockListResults &&
      pxnSettings
    ) {
      // loading default participant
      const searchParams = new URLSearchParams(history.location.search);
      const searchedWarehouseGroup =
        searchParams.get('warehouseGroup') ?? getDefaultWarehouseGroup(itemCounts);
      let warehouseGroup = searchedWarehouseGroup;
      // is reseller then check data is present for that warehouse group, if not then change the url and warehouse group to default
      if (
        searchedWarehouseGroup &&
        !stockListParticipants[searchedWarehouseGroup] &&
        pxnSettings.isPxnr
      ) {
        warehouseGroup = getDefaultWarehouseGroup(itemCounts);
        history.push({
          search: `warehouseGroup=${warehouseGroup}`,
        });
      }
      selectParticipant(`${warehouseGroup}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stockListParticipants, pxnSettings, itemCounts]);

  React.useEffect(() => {
    //call preferences api only for customers who have completed the customer onboarding process
    if (setupCompleted)
      (async () => {
        try {
          const selectedPreferencesOptions = await fetchUrl(
            'get',
            apiToUrlMap.shippingBillingPreferencesOptions,
            {},
            '',
            false,
            true
          );
          setSelectedPreferencesOptions(selectedPreferencesOptions.buyerOrderOptions);
        } catch (e: any) {
          console.error(e.message);
        }
      })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setupCompleted]);

  const displaySnackBars = (offers: any, salesOrders: any) => {
    const countOfOfferExpiring = getExpiringOffersCount(offers);
    const {
      countAwaitingPaymentOrdersPastDue,
      countOnHoldOrdersExpiring,
    } = getCountOfOrdersExpiring(salesOrders);

    if (countOfOfferExpiring) {
      awaitingOffersSnackbarRef?.current?.open(
        `${countOfOfferExpiring} of your Offers are Expiring Soon.`,
        {
          label: <span className="px-bold-600"> SEE OFFERS</span>,
          handler: () => {
            awaitingOffersSnackbarRef.current?.close();
            history.push('/cart');
          },
          variant: 'text',
        }
      );
    }
    if (countOnHoldOrdersExpiring) {
      expiringOnHoldOrdersSnackbarRef?.current?.open(
        `${countOnHoldOrdersExpiring} of your On Hold Orders are Expiring Soon.`,
        {
          label: <span className="px-bold-600"> SEE ORDERS</span>,
          handler: () => {
            expiringOnHoldOrdersSnackbarRef.current?.close();
            history.push('/orders');
          },
          variant: 'text',
        }
      );
    }
    if (countAwaitingPaymentOrdersPastDue) {
      expiringAwaitingPaymentOrdersRef.current?.open(
        `${countAwaitingPaymentOrdersPastDue} of your Awaiting Payment Orders will be Past Due soon.`,
        {
          label: <span className="px-bold-600"> SEE ORDERS</span>,
          handler: () => {
            expiringAwaitingPaymentOrdersRef.current?.close();
            history.push('/orders');
          },
          variant: 'text',
        }
      );
    }
    localStorage.removeItem('showStockListNotifications');
  };

  React.useEffect(() => {
    //call get offers and alerts for buyers who have completed the customer registration
    if (!setupCompleted) return;
    async function getOffers() {
      try {
        const showSnackbars = localStorage.getItem('showStockListNotifications');
        const [offers, orders] = await Promise.all([
          fetchUrl('GET', apiToUrlMap.offers, {}),
          showSnackbars && fetchUrl('get', apiToUrlMap.getOrdersV1, {}),
        ]);
        dispatch(stockOffersUpdate(offers || {}));
        if (showSnackbars) {
          setTimeout(() => displaySnackBars(offers, orders), 0);
        }
      } catch (err) {
        console.log(err);
      }
    }

    async function getAlerts() {
      try {
        const alerts = await fetchUrl('get', apiToUrlMap.stockAlerts, {});
        dispatch(stockAlertsSet(alerts || {}));
      } catch (err) {
        console.log(err);
      }
    }
    const canBuyOnline = canBuyOnlineFn(authorities);
    if (canBuyOnline && !isViewOnlyCustomer(enabledTags)) getOffers();
    if (authorities['CAN_SET_STOCKLIST_ALERT']) getAlerts();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorities, setupCompleted]);

  React.useEffect(() => {
    if (pxnSettings && stockListSettings) {
      setupStockList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pxnSettings, stockListSettings]);

  const setLevel0DefaultMenuData = (item: any) => {
    if (!selectedStockListSettings) return {};
    return selectedStockListSettings[item as keyof typeof selectedStockListSettings].reduce(
      (acc: any, subItem: any) => {
        return {
          ...acc,
          [subItem]: {
            _totals: 0,
          },
        };
      },
      {}
    );
  };

  //This Function is the entry point for filter that initializes the respective objects and returns the response from the filtering Algorithm
  const filterItems = () => {
    if (!selectedStockListSettings) {
      return { filteredGroupItems: [], menuData: {}, newFiltersInUseStateObj: null };
    }
    // add level0 filters by default
    const menuData: any = {
      categories: setLevel0DefaultMenuData('category'),
      warehouse: setLevel0DefaultMenuData('warehouse'),
      _totals: {
        itemsCount: 0,
        groupsCount: 0,
        quantity: 0,
      },
      currentSubCategoryValueArray: {},
      isNetQuantityToBeMasked: false,
      sortAlgorithm: {},
    };

    let newFiltersInUseStateObj: any = {
      ...filtersInUseState,
    };

    if (newFiltersInUseStateObj._isFilterting) newFiltersInUseStateObj._isFilterting = false;

    const responseObj = filterItemsUtil(itemGroupKeysState, menuData, newFiltersInUseStateObj, -1);

    for (let key of Object.keys(menuData['currentSubCategoryValueArray'])) {
      if (key === 'grade') {
        let filteredGradeArray = selectedStockListSettings['grade'].filter(
          (grade: any) => menuData['currentSubCategoryValueArray'][key].indexOf(grade) > -1
        );

        menuData['currentSubCategoryValueArray'][key] = filteredGradeArray;
      } else
        menuData['currentSubCategoryValueArray'][key].sort(
          getComparatorFunction(key, selectedStockListSettings['attributeSortAlgorithm'][key])
        );
    }

    const selectedCategory = newFiltersInUseStateObj['Level0']['category'];
    if (
      selectedStockListSettings.sortOrder &&
      !isEmpty(selectedStockListSettings.sortOrder) &&
      !isEmpty(responseObj.filteredGroupItems) &&
      menuData['sortAlgorithm'][selectedCategory]
    ) {
      const sortOrder = selectedStockListSettings.sortOrder;
      sortOrder.forEach((sortAttr: string) => {
        menuData['sortAlgorithm'][selectedCategory][sortAttr]?.sort(
          getComparatorFunction(
            sortAttr,
            selectedStockListSettings['attributeSortAlgorithm'][sortAttr]
          )
        );
      });

      responseObj.filteredGroupItems.sort((a: any, b: any) => {
        let sortResult = 0;
        for (let sortKey of sortOrder) {
          if (!menuData['sortAlgorithm'][selectedCategory][sortKey]) continue;
          sortResult =
            sortResult ||
            menuData['sortAlgorithm'][selectedCategory][sortKey].indexOf(
              a.groupAttributes[sortKey]
            ) -
              menuData['sortAlgorithm'][selectedCategory][sortKey].indexOf(
                b.groupAttributes[sortKey]
              );
        }
        return sortResult;
      });
    }

    return responseObj;
  };

  //This Function is to filter the items at a particular level if there is any filter applied at that level otherwise return all
  const filterCriteria = (level: number, item: any) => {
    if (level > 3) return true;

    if (level === -1) {
      item.quantityAvailable = item.quantityAvailable > 0 ? item.quantityAvailable : 0;
      const includeOutOfStockApplied =
        item.listPrice &&
        (filtersInUseState['Level0']['includeOutOfStock'] ? true : item.quantityAvailable > 0);
      const specialPriceApplied = !filtersInUseState.Level0.specialPrice
        ? true
        : specialPriceCondition(item.specialPrice, item.listPrice);
      return includeOutOfStockApplied && specialPriceApplied;
    } else if (level === 0) {
      const level0Applied = !filtersInUseState.Level0.category
        ? true
        : item.category === filtersInUseState.Level0.category;
      const warehouseApplied = !filtersInUseState.Level0.warehouse
        ? true
        : filtersInUseState.Level0.warehouse?.indexOf(`<|${item.warehouse}|>`) > -1;
      return level0Applied && warehouseApplied;
    } else {
      let currentLevelSubCategories: any = Object.keys(
        filtersInUseState[`Level${level}` as FilterInUseLevelType] || {}
      );

      let shouldAddItem = true;

      for (let i = 0; i < currentLevelSubCategories.length; i++) {
        const criteria = currentLevelSubCategories[i];

        if (
          filtersInUseState[`Level${level}` as FilterInUseLevelType][criteria].indexOf(
            `<|${item[criteria]}|>`
          ) === -1
        ) {
          shouldAddItem = false;
          break;
        }
        // increment current level
      }

      return shouldAddItem;
    }
  };

  // This Function is used to populate the tmpGroup for all the items that have been filtered so far
  const populateTmpGroup = (level: any, tmpGroup: any, item: any) => {
    tmpGroup.items.push(item);
    tmpGroup.quantityAvailable += item.quantityAvailable || 0;

    if (!tmpGroup.showMinPriceSymbol)
      tmpGroup.showMinPriceSymbol =
        tmpGroup.items.length > 1 &&
        getPriceBasedOnCondition(tmpGroup.items[tmpGroup.items.length - 1]) !==
          getPriceBasedOnCondition(tmpGroup.items[tmpGroup.items.length - 2]);

    tmpGroup.minPrice = Math.min(tmpGroup.minPrice, item.listPrice);
    tmpGroup.specialPrice = Math.min(tmpGroup.specialPrice, item.specialPrice || Number.MAX_VALUE);

    return item;
  };

  const getPriceBasedOnCondition = (item: any) => {
    if (
      item.specialPrice &&
      item.specialPrice !== Number.MAX_VALUE &&
      item.specialPrice >= item.listPrice
    ) {
      return item.specialPrice;
    }
    return item.listPrice;
  };

  //A Helper function that filters and computes menuData for each level that is passed as input with the updated filters state Obj computed so far
  const filterItemsUtil: any = (
    stockListGroups: any,
    menuData: any,
    newFiltersInUseStateObj: any,
    level: any
  ) => {
    if (!selectedStockListSettings) return [];

    const filteredGroupItems: any = [];
    // TODO: update this to select the correct category (there won't be any situation where category is not present)
    const selectedCategory = newFiltersInUseStateObj['Level0']['category'];

    let dontGoFurtherLevels =
      level > 3 ||
      (level > 0 &&
        newFiltersInUseStateObj[`Level${level}`] &&
        Object.keys(newFiltersInUseStateObj[`Level${level}`]).length === 0);

    let tmpGroup: any;

    stockListGroups.forEach((groupObj: any) => {
      tmpGroup = {
        ...groupObj,
        items: [],
        quantityAvailable: 0,
        minPrice: Number.MAX_VALUE,
        specialPrice: Number.MAX_VALUE,
        showMinPriceSymbol: false,
        datetimeUpdated: null,
        isOfferAccepted: selectedStockListSettings.warehouseAcceptingOffer.includes(
          groupObj.wareHouse
        ),
      };

      const localFilteredItems = groupObj.items.filter((item: any) => filterCriteria(level, item));

      localFilteredItems.map((item: any) => populateTmpGroup(level, tmpGroup, item));

      if (tmpGroup.items.length) {
        // TODO: Change dependency on title to display stocklist item
        // We're using this title to display stocklist item, if title attribute is present then show otherwise render empty div
        tmpGroup.title = groupObj.groupDescription;
        tmpGroup.key = groupObj.groupId;
        tmpGroup.countOfItems = tmpGroup.items.length;
        filteredGroupItems.push(tmpGroup);
      }

      menuData._totals.groupsCount += 1;
      // adding the filtered items array length
      menuData._totals.itemsCount += localFilteredItems.length;
    });

    if (filteredGroupItems.length === 0) {
      dontGoFurtherLevels = true;
      if (level === 0) {
        updateFiltersInUse(
          true,
          getDefaultSelectedCategory(selectedStockListSettings),
          0,
          'initial'
        );
        newFiltersInUseStateObj._isFilterting = true;
      } else newFiltersInUseStateObj[`Level${level}`] = {};

      if (level === 2) {
        newFiltersInUseStateObj[`Level${level + 1}`] = {};
      }
    }

    if (level > -1)
      stockListGroups.forEach((groupObj: any) => {
        const category = groupObj.items[0].category;
        const warehouse = groupObj.items[0].warehouse;

        if (level <= 3)
          groupObj.items.forEach((item: any) => {
            if (level === 0) {
              menuData['categories'][category]._totals += 1;
              if (!menuData.warehouse[warehouse]) menuData.warehouse[warehouse] = {};
              // check warehouse for currently selected category otherwise check warehouse for all categories
              let addToWarehouseTotal = 0;
              if (
                !filtersInUseState.Level0.category ||
                category === filtersInUseState.Level0.category
              )
                addToWarehouseTotal = 1;
              menuData.warehouse[warehouse]._totals += addToWarehouseTotal;
              return;
            }

            // TODO: Update category
            const currentSubCategories = selectedStockListSettings.searchLevels[selectedCategory]?.[
              level - 1
            ]?.filter((val: string) => val !== 'warehouse');

            for (let j = 0; j < currentSubCategories?.length; j++) {
              const criteria = currentSubCategories[j];

              if (item.hasOwnProperty(criteria) && item[criteria]) {
                if (!menuData['categories'][selectedCategory][criteria]) {
                  menuData['categories'][selectedCategory][criteria] = {};
                  menuData['currentSubCategoryValueArray'][criteria] = [];
                }

                if (
                  !menuData['categories'][selectedCategory][criteria].hasOwnProperty(item[criteria])
                ) {
                  menuData['categories'][selectedCategory][criteria][item[criteria]] = {
                    itemsCount: 0,
                  };
                  menuData['currentSubCategoryValueArray'][criteria].push(item[criteria]);
                }

                const filteredCriteriaArray: any = Object.keys(
                  newFiltersInUseStateObj[`Level${level}`] || {}
                );

                let isItemFilterCriteriaSatisfied: boolean = true;

                filteredCriteriaArray
                  .filter((element: any) => element !== criteria)
                  .forEach((filteredCriteria: any) => {
                    if (
                      newFiltersInUseStateObj[`Level${level}`][filteredCriteria].indexOf(
                        `<|${item[filteredCriteria]}|>`
                      ) === -1
                    ) {
                      isItemFilterCriteriaSatisfied = false;
                    }
                  });

                if (isItemFilterCriteriaSatisfied) {
                  menuData['categories'][selectedCategory][criteria][
                    item[criteria]
                  ].itemsCount += 1;
                }
              }
            }
            if (selectedStockListSettings.sortOrder) {
              for (let j = 0; j < selectedStockListSettings.sortOrder.length; j++) {
                const sortAttribute = selectedStockListSettings.sortOrder[j];
                if (item.hasOwnProperty(sortAttribute) && item[sortAttribute]) {
                  if (!menuData['sortAlgorithm'][selectedCategory]) {
                    menuData['sortAlgorithm'][selectedCategory] = {};
                  }
                  if (!menuData['sortAlgorithm'][selectedCategory][sortAttribute]) {
                    menuData['sortAlgorithm'][selectedCategory][sortAttribute] = [];
                  }
                  if (
                    !menuData['sortAlgorithm'][selectedCategory][sortAttribute].includes(
                      item[sortAttribute]
                    )
                  )
                    menuData['sortAlgorithm'][selectedCategory][sortAttribute].push(
                      item[sortAttribute]
                    );
                }
              }
            }

            menuData.noOfLevelsToBeDisplayed = level;
          });

        if (dontGoFurtherLevels) {
          if (
            selectedStockListSettings['maxItemQuantityToBeDisplayed'] &&
            groupObj.quantityAvailable > selectedStockListSettings['maxItemQuantityToBeDisplayed']
          ) {
            menuData.isNetQuantityToBeMasked = true;
          }

          menuData._totals.quantity +=
            selectedStockListSettings['maxItemQuantityToBeDisplayed'] &&
            groupObj.quantityAvailable > selectedStockListSettings['maxItemQuantityToBeDisplayed']
              ? selectedStockListSettings['maxItemQuantityToBeDisplayed']
              : groupObj.quantityAvailable;
        }
      });

    if (filteredGroupItems.length === 0) {
      return { filteredGroupItems: stockListGroups, menuData, newFiltersInUseStateObj };
    }

    if (level > 0 && level <= 3) {
      Object.keys(newFiltersInUseStateObj[`Level${level}`] || {}).forEach((category: any) => {
        let splittedArray = String(newFiltersInUseStateObj[`Level${level}`][category] || '')
          .replace(/\|><\|/g, ',')
          .replace(/<\|/g, '')
          .replace(/\|>/g, '')
          .split(',');

        splittedArray.forEach((subcategory: any) => {
          if (
            !menuData['categories'][selectedCategory][category][subcategory] ||
            !menuData['categories'][selectedCategory][category][subcategory].itemsCount
          ) {
            newFiltersInUseStateObj[`Level${level}`][category] = String(
              newFiltersInUseStateObj[`Level${level}`][category] || ''
            ).replace(`<|${subcategory}|>`, '');
          }
        });

        if (newFiltersInUseStateObj[`Level${level}`][category] === '') {
          delete newFiltersInUseStateObj[`Level${level}`][category];
        }
      });

      if (Object.keys(newFiltersInUseStateObj[`Level${level}`] || {}).length === 0) {
        if (level === 2) newFiltersInUseStateObj[`Level${level + 1}`] = {};
      }
    }
    isReloadRef.current = dontGoFurtherLevels;
    if (dontGoFurtherLevels) return { filteredGroupItems, menuData, newFiltersInUseStateObj };

    menuData._totals = {
      itemsCount: 0,
      groupsCount: 0,
      quantity: 0,
    };

    return filterItemsUtil(filteredGroupItems, menuData, newFiltersInUseStateObj, level + 1);
  };

  const getComparatorFunction = (subCategory: any, sortAlgorithm: any) => {
    if (!selectedStockListSettings) return null;
    if (sortAlgorithm === 'Default') {
      return function (a: any, b: any) {
        const a_labelled =
          selectedStockListSettings[`${subCategory}Labels` as KeysOfStockListSettings] &&
          selectedStockListSettings[`${subCategory}Labels` as KeysOfStockListSettings][a]
            ? selectedStockListSettings[`${subCategory}Labels` as KeysOfStockListSettings][a]
            : a;

        const b_labelled =
          selectedStockListSettings[`${subCategory}Labels` as KeysOfStockListSettings] &&
          selectedStockListSettings[`${subCategory}Labels` as KeysOfStockListSettings][b]
            ? selectedStockListSettings[`${subCategory}Labels` as KeysOfStockListSettings][b]
            : b;

        return a_labelled.localeCompare(b_labelled, undefined, { sensitivity: 'base' });
      };
    }

    if (sortAlgorithm === 'AlphanumericAsNumeric')
      return function (a: any, b: any) {
        try {
          let [a_number, a_word] = a.match(/\D+|\d+/g);
          let [b_number, b_word] = b.match(/\D+|\d+/g);

          a_number = parseInt(a_number);
          b_number = parseInt(b_number);

          if (a_word === b_word) {
            return a_number > b_number ? 1 : a_number < b_number ? -1 : 0;
          }

          return a_word > b_word ? 1 : a_word < b_word ? -1 : 0;
        } catch (error) {
          console.error('error comparator', subCategory, a, b);
        }
      };

    if (sortAlgorithm === 'FilterOrder') {
      return function (a: any, b: any) {
        const filterOrderObj =
          selectedStockListSettings?.filterOrder?.[
            subCategory as keyof typeof selectedStockListSettings
          ];
        if (!filterOrderObj) return 0;
        const a_number = filterOrderObj[a],
          b_number = filterOrderObj[b];
        return a_number > b_number ? 1 : a_number < b_number ? -1 : 0;
      };
    }

    return null;
  };

  const getDefaultWarehouseGroup = (itemCounts: { [key: string]: number } | undefined) => {
    if (!itemCounts) return;
    if (pxnSettings.pxnrParticipantId === defaultParticipant) return defaultParticipant;

    let isSelectOrderConfigured = true;
    const warehouseGroups = Object.keys(pxnWarehousesObj) || [];

    const sortedWarehouseGroups = warehouseGroups
      .filter((id) => pxnWarehousesObj[id].warehouses?.length)
      .sort((id1, id2) => {
        const warehouse1Order = pxnWarehousesObj[id1].warehouseGroupSelectOrder;
        const warehouse2Order = pxnWarehousesObj[id2].warehouseGroupSelectOrder;
        if (checkNullOrUndefined(warehouse1Order) || checkNullOrUndefined(warehouse2Order))
          isSelectOrderConfigured = false;
        return warehouse1Order - warehouse2Order;
      })
      .map((particiapntId) => +particiapntId);
    const sortedSelectableWarehouseGroups = sortedWarehouseGroups.filter(
      (id) => itemCounts[id] > 0 && stockListSettings?.[id]
    );
    if (!sortedWarehouseGroups.length && warehouseGroups.length) return warehouseGroups[0];
    if (!sortedSelectableWarehouseGroups.length) return sortedWarehouseGroups[0];
    if (isSelectOrderConfigured) return +sortedSelectableWarehouseGroups[0];
    return sortedSelectableWarehouseGroups.includes(pxnSettings.pxnrParticipantId)
      ? pxnSettings.pxnrParticipantId
      : +sortedSelectableWarehouseGroups[0];
  };

  const getDefaultSelectedCategory = (settings: StockListSettings): string => {
    let categoryToBeFilteredOutIndex: number = settings['category'].length;
    Object.keys(stockListResults?.groups || {}).forEach((key: any) => {
      stockListResults.groups[key].items.forEach((itemObj: any) => {
        if (
          settings['category'].indexOf(itemObj['category']) > -1 &&
          itemObj.quantityAvailable > 0 &&
          itemObj.listPrice
        )
          // TODO: update condition: itemObj.quantityAvailable should be > 0
          categoryToBeFilteredOutIndex = Math.min(
            settings['category'].indexOf(itemObj['category']),
            categoryToBeFilteredOutIndex
          );
      });
    });
    return settings['category'][categoryToBeFilteredOutIndex];
  };

  const setSpecialPricingFilterAndExportFilterUsed = (settings: StockListSettings) => {
    // Check if list of keys was created
    const specialPriceItems: any = [];
    const participantId =
      new URLSearchParams(history.location.search).get('warehouseGroup') ||
      pxnSettings.pxnrParticipantId;
    if (!itemGroupKeysState) {
      const itemGroupKeys = Object.keys(stockListResults.groups).map((key: any) => {
        const groups = stockListResults.groups[key];
        groups.items = groups.items.map((item: any) => {
          if (
            item.quantityAvailable > 0 &&
            specialPriceCondition(item.specialPrice, item.listPrice)
          ) {
            // special pricing
            specialPriceItems.push(item);
          }
          if (selectedStockListSettings?.searchEmptyValues) {
            Object.keys(selectedStockListSettings.searchEmptyValues).forEach((searchEmptyValue) => {
              if (
                !item[searchEmptyValue] &&
                selectedStockListSettings.searchEmptyValues?.[searchEmptyValue].allowed
              ) {
                item[searchEmptyValue] =
                  selectedStockListSettings.searchEmptyValues?.[searchEmptyValue].label ||
                  'Unknown';
              }
            });
          }

          return {
            ...item,
          };
        });
        return groups;
      });
      if (
        !Object.keys(specialPriceFilter).includes(participantId) &&
        participantId === pxnSettings.pxnrParticipantId
      ) {
        const updatedSpecialPriceFilter: any = {
          ...specialPriceFilter,
          [participantId]: specialPriceItems,
        };
        if (isEmpty(updatedSpecialPriceFilter[participantId])) {
          removeSpecialPricingFromUrl();
        }
        setSpecialPricingFilter(updatedSpecialPriceFilter);
      }
      setItemGroupKeysState(itemGroupKeys);
      formattedTimeStamp.current = dateFormat(stockListResults['lastUpdateddDate']);
      if (!filtersInUseState.Level0.category) {
        const selectedCategory = getDefaultSelectedCategory(settings);
        const specialPrice = filtersInUseState.Level0.specialPrice;
        // TODO: introduce a concept of warehouseGroup in backend
        const warehouseGroup =
          filtersInUseState.Level0.warehouseGroup || getDefaultWarehouseGroup(itemCounts);
        setExportFilteredItems({
          includeOutOfStock: false,
          itemsWithSpecialPriceOnly: specialPrice,
          filters: {
            category: [selectedCategory],
            warehouseGroup: [warehouseGroup],
            warehouse: null,
          },
        });
        setFiltersInUseState({
          _isFilterting: false,
          _isDataLoaded: true,
          Level0: {
            category: selectedCategory,
            includeOutOfStock: false,
            specialPrice,
            warehouseGroup: `${warehouseGroup}`,
            warehouse: '',
          },
          Level1: {},
          Level2: {},
          Level3: {},
        });
      }
    }
  };

  // STEP 2 - Once groupKeys are available, start filtering
  React.useEffect(() => {
    /// loadingState = 0 means loading for the first time
    if (loadingState === 0 && itemGroupKeysState && filtersInUseState._isDataLoaded) {
      setLoadingState(1);
      const { filteredGroupItems, menuData } = filterItems();
      setFilteredItemsState(filteredGroupItems);
      if (filtersInUseState?.Level0?.warehouseGroup) {
        setItemCounts((prev) => {
          if (!prev) return prev;
          const warehouseGroupCount = menuData?.warehouse
            ? Object.keys(menuData?.warehouse || {}).reduce(
                (total, curr) => (total += menuData?.warehouse?.[curr]._totals || 0),
                0
              )
            : prev[filtersInUseState.Level0.warehouseGroup];
          return {
            ...prev,
            [filtersInUseState.Level0.warehouseGroup]: warehouseGroupCount,
          };
        });
      }
      setMenuDataState(menuData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingState, itemGroupKeysState, filtersInUseState]);

  // STEP 1 - Once all data is available, get groupKeys
  React.useEffect(() => {
    if (stockListResults && selectedStockListSettings) {
      setSpecialPricingFilterAndExportFilterUsed(selectedStockListSettings);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStockListSettings, stockListResults]);

  // -- Support to STEP 0 - Get numeric level of a given filter within a category
  const getLevelOfFilter = (category: string, filterName: string): number => {
    if (
      filterName === 'category' ||
      filterName === 'includeOutOfStock' ||
      filterName === 'specialPrice' ||
      filterName === 'warehouseGroup' ||
      filterName === 'warehouse'
    ) {
      return 0;
    }

    if (!selectedStockListSettings) return -1;
    const cat = selectedStockListSettings.searchLevels[category];

    if (cat?.length >= 1 && cat[0].indexOf(filterName) > -1) {
      return 1;
    }
    if (cat?.length >= 2 && cat[1].indexOf(filterName) > -1) {
      return 2;
    }
    if (cat?.length >= 3 && cat[2].indexOf(filterName) > -1) {
      return 3;
    }
    return -1;
  };

  const disableScrollTopButton = () => {
    if (!topOfResultsRef.current) return;
    setTimeout(() => {
      const hideButton = window.scrollY <= window.screen.height;
      if (hideButton) {
        topOfResultsRef.current.classList.add('go-to-top');
      } else {
        topOfResultsRef.current.classList.remove('go-to-top');
      }
    }, 0);
  };

  const goToTop = (e: any) => {
    e.preventDefault();
    setTimeout(() => {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, 0);
  };

  const renderChips = useMemo(() => {
    const listOfChips: any = [];
    if (!selectedStockListSettings) return listOfChips;

    ['Level1', 'Level2', 'Level3'].forEach((level) => {
      filtersInUseState &&
        filtersInUseState[level as FilterInUseLevelType] &&
        Object.keys(filtersInUseState[level as FilterInUseLevelType]).forEach(
          (filteringAttribute) => {
            listOfChips.push(
              ...String(filtersInUseState[level as FilterInUseLevelType][filteringAttribute] || '')
                .replace(/\|><\|/g, ',')
                .replace(/<\|/g, '')
                .replace(/\|>/g, '')
                .split(',')
                .map((filteredValue: string) => (
                  <Chip
                    key={`${level}-${filteringAttribute}-${filteredValue}`}
                    label={
                      selectedStockListSettings[
                        `${filteringAttribute}Labels` as KeysOfStockListSettings
                      ] &&
                      selectedStockListSettings[
                        `${filteringAttribute}Labels` as KeysOfStockListSettings
                      ][filteredValue]
                        ? selectedStockListSettings[
                            `${filteringAttribute}Labels` as KeysOfStockListSettings
                          ][filteredValue]
                        : filteredValue
                    }
                    color="primary"
                    className="margin-right-1 margin-bottom-1 px-filter-on"
                    onDelete={() =>
                      updateFiltersInUse(
                        false,
                        filteredValue,
                        parseInt(level[level.length - 1]),
                        filteringAttribute
                      )
                    }
                  />
                ))
            );
          }
        );
    });

    return listOfChips;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersInUseState]);

  const searchBasedOnQuery = () => {
    if (!selectedStockListSettings) return;
    const queryString = window.location.search.slice(1);
    if (queryString) {
      const mySearchParams = new URLSearchParams(queryString);
      const queryObj: any = {};

      mySearchParams.forEach((value, key) => {
        queryObj[key] = value;
      });
      getFilteredItems(queryObj);
      queryObj['includeOutOfStock'] = queryObj['includeOutOfStock'] === 'true';
      queryObj['specialPrice'] = queryObj['specialPrice'] === 'true';
      queryObj['warehouseGroup'] = queryObj['warehouseGroup'] || null;
      queryObj['warehouse'] = queryObj['warehouse'] || null;
      queryObj['category'] = queryObj['category'] || null;

      const paramKeys = Object.keys(queryObj);
      let filtersInUseTemp: any = null;
      paramKeys.forEach((param: any) => {
        if (param === 'p') {
          //const page = Number.isInteger(queryObj[param]) ? parseInt(queryObj[param], 10) : 1;
          //setPageNumState({ _hasChanged: true, currentPage: page });
        } else {
          const category =
            queryObj.hasOwnProperty('category') && queryObj.category
              ? queryObj.category
              : filtersInUseState.Level0.category;
          if (!filtersInUseTemp) {
            filtersInUseTemp = {
              Level0: {},
              Level1: {},
              Level2: {},
              Level3: {},
            };
          }
          const level: number = getLevelOfFilter(category, param);
          if (level >= 0) {
            if (!filtersInUseTemp.hasOwnProperty(`Level${level}`)) {
              filtersInUseTemp[`Level${level}`] = {};
            }
            if (!filtersInUseTemp[`Level${level}`].hasOwnProperty(`${param}`)) {
              filtersInUseTemp[`Level${level}`][`${param}`] = '';
            }
            let value: any = '';
            if (
              param === 'category' ||
              param === 'includeOutOfStock' ||
              param === 'specialPrice' ||
              param === 'warehouseGroup'
            ) {
              value = queryObj[param];
              filtersInUseTemp[`Level${level}`][`${param}`] = value;
            } else {
              if (param === 'warehouse' && !queryObj[param]) return;
              value = queryObj[param].split(',');
              value = '<|' + value.join('|><|') + '|>';
              filtersInUseTemp[`Level${level}`][`${param}`] += value;
            }
          }
        }
      });

      if (filtersInUseTemp) {
        setFiltersInUseState({
          ...filtersInUseTemp,
          Level0: {
            ...filtersInUseState.Level0,
            ...filtersInUseTemp.Level0,
            warehouseGroup:
              queryObj.warehouseGroup ||
              filtersInUseState.Level0.warehouseGroup ||
              getDefaultWarehouseGroup(itemCounts),
            category:
              filtersInUseTemp.Level0.category ||
              getDefaultSelectedCategory(selectedStockListSettings),
          },
          _isDataLoaded: true,
          _isFilterting: filtersInUseState._isFilterting,
        });
      }
    }

    //setCheckUrlParamsState({ hasParams: true, queryObj });
  };

  // 0 - Onload... Get URL params and update states
  React.useEffect(() => {
    if (!selectedStockListSettings) return;
    searchBasedOnQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStockListSettings]);

  // 0 - Onload... Start monitoring for changes on query-params
  React.useEffect(() => {
    if (props.urlState.hasOwnProperty('eventType') && props.urlState.eventType === 'popstate') {
      setLoadingState(0);
      searchBasedOnQuery();
    }

    disableScrollTopButton();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.urlState]);

  React.useEffect(() => {
    disableScrollTopButton();
  }, [pageDataState]);

  useEffect(() => {
    const pxSpecialPriceSnackBar = localStorage.getItem('pxSpecialPriceSnackBar');
    if (!isEmpty(specialPriceFilter[pxnSettings?.pxnrParticipantId]) && pxSpecialPriceSnackBar) {
      setTimeout(() => {
        showSpecialPriceSnackbar(specialPriceFilter[pxnSettings.pxnrParticipantId]);
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specialPriceFilter]);

  const showSpecialPriceSnackbar = (specialPriceFilterItems: any) => {
    snackBarRef?.current?.open(
      `${specialPriceFilterItems.length} Items have Special Pricing for You right now `,
      {
        label: <span className="px-bold-600"> SEE YOUR SPECIALS</span>,
        handler: () => {
          snackBarRef.current?.close();
          applySpecialPricingFilter();
        },
        variant: 'text',
      }
    );
    localStorage.removeItem('pxSpecialPriceSnackBar');
  };

  const removeSpecialPricingFromUrl = () => {
    const queryParams = new URLSearchParams(history.location.search);
    if (queryParams.has('specialPrice')) {
      queryParams.delete('specialPrice');
      history.push({ search: queryParams.toString() });
      searchBasedOnQuery();
      return;
    }
    return;
  };

  const applySpecialPricingFilter = () => {
    setFiltersInUseState((prev: any) => ({
      ...prev,
      _isDataLoaded: true,
      _isFilterting: true,
      Level0: {
        ...prev.Level0,
        specialPrice: true,
      },
    }));
    const queryParams = new URLSearchParams(history.location.search);
    // Add or update query parameters as needed
    queryParams.set('specialPrice', 'true');

    // Update the URL with the new query parameters
    history.push({ search: queryParams.toString() });
    setExportFilteredItems({
      ...exportFilteredItems,
      itemsWithSpecialPriceOnly: true,
    });
  };

  const getFilteredItems = (selectedFilters: { [key: string]: string | string[] | boolean }) => {
    const {
      includeOutOfStock = false,
      specialPrice = selectedFilters.specialPrice || false,
      ...rest
    } = selectedFilters;
    for (const filter in rest) {
      rest[filter] = (rest[filter] as string).split(',');
    }
    // update this category to default if category is not in selected filters
    if (!rest.category) rest.category = [getDefaultSelectedCategory(selectedStockListSettings!)];
    setExportFilteredItems({
      includeOutOfStock,
      itemsWithSpecialPriceOnly: specialPrice,
      ...{ filters: rest },
    });
  };

  const exportItems = async (option: string) => {
    setLoading(true);
    try {
      const { warehouseGroup, ...rest } = exportFilteredItems.filters;
      const body =
        option === 'ALL'
          ? { includeOutOfStock: false, itemsWithSpecialPriceOnly: false }
          : {
              ...exportFilteredItems,
              filters: rest,
            };
      if (option !== 'ALL' && !body.filters.warehouse && selectedStockListSettings?.warehouse)
        body.filters.warehouse = selectedStockListSettings?.warehouse;
      let headers: any = {};
      const warehouseGroupId = filtersInUseState?.Level0?.warehouseGroup;
      if (warehouseGroupId) headers['pxns-participant-id'] = warehouseGroupId;
      await exportData({ url: apiToUrlMap.stockListExport, method: 'POST', body, headers });
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const toggleExportDropdown = (e: React.SyntheticEvent) => {
    setExportRef(e.currentTarget);
    setIsExportDrodownOpen((isExportDropdownOpen) => !isExportDropdownOpen);
  };

  const closeExportDropdown = (event: any) => {
    setExportRef(null);
    setIsExportDrodownOpen(false);
  };

  const openGradePopup = (grade?: string) => {
    setSelectedGrade(grade);
    setPopupState({ ...popupState, grades: true });
  };

  const openWarehouseGroupDescriptionDialog = (
    participantId: any,
    type: 'info' | 'prompt',
    promptSuccessCb?: any
  ) => (): boolean => {
    // doesn't have warehouse groups
    if (!pxnWarehousesObj || !Object.keys(pxnWarehousesObj).length) return false;

    const updatedPopupState = { ...popupState };
    const warehouseGroupDescription = (
      pxnWarehousesObj?.[participantId]?.warehouseGroupDescription || ''
    ).trim();
    // don't open dialog if there is no warehouse group description
    if (!warehouseGroupDescription) return false;

    // when trying to open dialog as a prompt, validate if user has acknowledged to don't show again (when placing offers).
    if (type === 'prompt' && selectedPreferencesOptions) {
      const warehouseOption = selectedPreferencesOptions.warehouseOption || [];
      const participantWarehouseOption = warehouseOption.find(
        (option) => option.participantId === participantId
      );
      const isAcknowledged = !!(
        participantWarehouseOption && participantWarehouseOption.warehouseGroupInfoAcknowledged
      );

      if (isAcknowledged) return false;
    }
    updatedPopupState.warehouseGroupDescription = true;
    setPopupState(updatedPopupState);
    setSelectedWarehouseGroup({ type, participantId, promptSuccessCb });
    return true;
  };

  const acknowledgeWarehouseGroupDescriptionDialog = async (participantId: any) => {
    try {
      setLoading(true);
      const updatedPreferences = {
        ...(selectedPreferencesOptions || {}),
      } as IGetShippingPaymentDetails;
      const updatedWarehouseOption = [...updatedPreferences.warehouseOption] || [];
      const warehouseOptionIdx = updatedWarehouseOption.findIndex(
        (option) => option.participantId === +participantId
      );

      if (warehouseOptionIdx > -1) {
        updatedWarehouseOption[warehouseOptionIdx].warehouseGroupInfoAcknowledged = true;
      } else {
        updatedWarehouseOption.push({
          participantId: +participantId,
          warehouseGroupInfoAcknowledged: true,
          warehouseGroupInfoAcknowledgedBy: null,
          warehouseGroupInfoAcknowledgedDate: null,
        });
      }
      updatedPreferences.warehouseOption = updatedWarehouseOption;

      await fetchUrl('POST', apiToUrlMap.shippingBillingPreferencesOptions, {
        body: updatedPreferences,
      });
      setSelectedPreferencesOptions(updatedPreferences);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const closeWarehouseGroupDescriptionDialog = () => {
    setSelectedWarehouseGroup(undefined);
    setPopupState({ ...popupState, warehouseGroupDescription: false });
  };

  function goToCartPage() {
    //Redirect to cart page only when the buyer has completed the customer registration process
    if (!setupCompleted) setAccountSetupPopup(true);
    else {
      history.push({
        pathname: '/cart',
        state: { participantId: selectedStockListSettings!.pxParticipantId },
      });
    }
  }

  const showYourSpecialFilter = () =>
    !isEmpty(specialPriceFilter[pxnSettings?.pxnrParticipantId]) &&
    (new URLSearchParams(history.location.search).get('warehouseGroup') ||
      pxnSettings.pxnrParticipantId) === pxnSettings.pxnrParticipantId;

  const canBuyOnline = canBuyOnlineFn(authorities),
    showBuyOnlineBtn = !isViewOnlyCustomer(enabledTags);

  const readyToShowFilterCounts = useMemo(
    () => menuDataState?._totals?.itemsCount > 0 && filtersInUseState?.Level0?.category,
    [menuDataState?._totals?.itemsCount, filtersInUseState?.Level0?.category]
  );

  return selectedStockListSettings ? (
    <>
      <LoadingDialog isDialogOpen={loading} />

      <div className="stocklist-snackbars">
        <SnackBarWithActionProps
          ref={snackBarRef}
          startAdornmentIcon={<Star />}
          className="px-special-price-snackbar"
          snackBarProps={{
            ClickAwayListenerProps: { mouseEvent: false },
            'data-id': 'specialPriceSnackbar',
          }}
        />
        <SnackBarWithActionProps
          ref={awaitingOffersSnackbarRef}
          startAdornmentIcon={<Info />}
          className="px-orders-offers-notification-snackbar"
          snackBarProps={{
            ClickAwayListenerProps: { mouseEvent: false },
            'data-id': 'awaitingOffersSnackbar',
          }}
        />
        <SnackBarWithActionProps
          ref={expiringOnHoldOrdersSnackbarRef}
          startAdornmentIcon={<Info />}
          className={`px-orders-offers-notification-snackbar`}
          snackBarProps={{
            ClickAwayListenerProps: { mouseEvent: false },
            'data-id': 'expiringOnHoldOrdersSnackbar',
          }}
        />
        <SnackBarWithActionProps
          ref={expiringAwaitingPaymentOrdersRef}
          startAdornmentIcon={<Info />}
          className={`px-orders-offers-notification-snackbar`}
          snackBarProps={{
            ClickAwayListenerProps: { mouseEvent: false },
            'data-id': 'expiringAwaitingPaymentSnackbar',
          }}
        />
      </div>
      <div
        id="stocklist-container"
        className={`grid-x ${openDifferentView ? 'hide' : 'show'} px-offset-bottom-mobile-view`}
      >
        <h2 data-id="stock-list-heading" className="cell small-12 capitalize">
          {I18n?.stockList?.i18n_value || 'Stock List'}
        </h2>
        {pageDataState?.length ? (
          <div className="cell small-12 large-4">
            <div className="small text-muted">{formattedTimeStamp.current}</div>
          </div>
        ) : null}
        <div
          className={`cell small-12 ${
            pageDataState?.length ? 'large-8' : 'large-offset-4 large-8'
          } px-stock`}
        >
          <ul className="px-daily-report">
            {configState['stockListTutorialUrl'] ? (
              <li>
                <TertiaryButton
                  onClick={() => {
                    setPopupState({ ...popupState, tutorial: true });
                  }}
                  disabled={false}
                  data-id="tutorial"
                >
                  {I18n?.tutorial?.i18n_value || 'TUTORIAL'}
                </TertiaryButton>
              </li>
            ) : null}
            {configState['gradeScaleUrl'] ? (
              <li>
                <TertiaryButton
                  onClick={() => {}}
                  disabled={false}
                  data-id="gradingScale"
                  className={'paddin-0 p-t-px-6 p-b-px-6 p-r-px-8 p-l-px-6'}
                >
                  <a
                    className="gradeScaleUrl"
                    href={configState['gradeScaleUrl']}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {I18n?.gradingScale?.i18n_value || 'GRADING SCALE'}
                  </a>
                </TertiaryButton>
              </li>
            ) : null}
            <li className="px-stock-alert-badge">
              <PrimaryBadge badgeContent={alertsBadgeNumber} showZero={false} data-id="totalAlerts">
                <TertiaryButton
                  onClick={async () => {
                    history.push('/stock-alerts');
                  }}
                  disabled={!authorities['CAN_SET_STOCKLIST_ALERT']}
                  data-id="stockAlerts"
                >
                  {I18n?.stockAlert?.i18n_value || 'STOCK ALERTS'}
                </TertiaryButton>
              </PrimaryBadge>
            </li>
            <li>
              <TertiaryButton
                onClick={async () => {
                  setPopupState({ ...popupState, dailyReport: true });
                }}
                disabled={!authorities['CAN_SUBSCRIBE_DAILY_REPORT']}
                data-id="dailyReport"
              >
                {I18n?.dailyReport?.i18n_value || 'DAILY REPORT'}
              </TertiaryButton>
            </li>
            {showBuyOnlineBtn && (
              <li>
                <TertiaryButton
                  onClick={async () => {
                    //The buyer can import only after completed customer registration process
                    if (!setupCompleted) setAccountSetupPopup(true);
                    else setPopupState({ ...popupState, import: true });
                  }}
                  disabled={!canBuyOnline}
                  data-id="import"
                >
                  {I18n?.import?.i18n_value || 'IMPORT'}
                </TertiaryButton>
              </li>
            )}
            <li>
              {/* <TertiaryButton onClick={onClickExport} data-id="export">
                {I18n?.export?.i18n_value || 'EXPORT'}
              </TertiaryButton> */}
              <TertiaryButton
                id="composition-button"
                aria-controls={isExportDropdownOpen ? 'composition-menu' : undefined}
                aria-expanded={isExportDropdownOpen ? 'true' : undefined}
                aria-haspopup="true"
                data-id="export"
                onClick={toggleExportDropdown}
              >
                {I18n?.export?.i18n_value || 'EXPORT'}
              </TertiaryButton>
              {isExportDropdownOpen && (
                <Popper
                  open={isExportDropdownOpen}
                  placement="bottom-end"
                  className="px-popper-menu"
                  transition
                  data-id="exportMenu"
                  anchorEl={exportRef}
                >
                  <ClickAwayListener onClickAway={closeExportDropdown}>
                    <MenuList>
                      <MenuItem key="ALL" data-id="exportAll" onClick={(e) => exportItems('ALL')}>
                        {I18n?.exportAllItems?.i18n_value || 'Export All Items'}
                      </MenuItem>
                      <MenuItem
                        key="FILTERED"
                        data-id="exportFiltered"
                        onClick={(e) => exportItems('FILTERED')}
                      >
                        {I18n?.exportFilteredItems?.i18n_value || 'Export Filtered Items'}
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Popper>
              )}
            </li>
            {showBuyOnlineBtn && (
              <li className="px-cart-icon-li hover-bg-light">
                <PrimaryIconButton
                  className={`${!canBuyOnline ? `disabled` : ''} px-stocklist-cart-icon-button`}
                  onClick={goToCartPage}
                >
                  <PrimaryBadge badgeContent={cartNumber} showZero={false} data-id="totalCartItems">
                    <ShoppingCartIcon
                      className="px-stocklist-cart-icon"
                      color={!canBuyOnline ? 'disabled' : 'primary'}
                    />
                  </PrimaryBadge>
                </PrimaryIconButton>
              </li>
            )}
          </ul>
        </div>
        <div className="cell small-12 grid-x px-stocklist">
          <div className="cell small-12 show-for-sm-only px-stock-list-sm-filter">
            <span
              className="link uppercase"
              onClick={() => {
                setShowHideFilter(!showHideFilter);
              }}
            >
              <FilterListIcon className="vertical-middle" />
              <span>{I18n?.filter?.i18n_value || 'FILTER'}</span>
            </span>
          </div>
          <div
            className={`cell px-filters px-stock-list-sm-filter ${showHideFilter ? 'show' : ''}`}
            style={selectedStockListSettings ? { display: 'initial' } : { display: 'none' }}
          >
            <div
              className="px-filters-backdrop"
              onClick={() => {
                setShowHideFilter(false);
              }}
            ></div>
            <div className="px-filters-bar">
              <FormGroup className="px-filters-items">
                <div className="filters-header-group grid-x align-justify align-middle m-t-px-10 m-b-px-12">
                  <h3 className="filters-header margin-0">
                    {I18n?.filter?.i18n_value || 'Filters'}
                  </h3>
                  <TertiaryButton
                    className="filters-header px-font-size-14px padding-0"
                    data-id="filterClear"
                    onClick={() =>
                      handleChange(
                        { target: { value: selectedStockListSettings.category[0] } },
                        0,
                        'category',
                        true
                      )
                    }
                  >
                    {I18n?.clear?.i18n_value || 'CLEAR'}
                  </TertiaryButton>
                </div>
                {!!selectedStockListSettings?.canSelectOutOfStockFilter && (
                  <FormControlLabel
                    value={'includeOutOfStock'}
                    data-id="includeOutOfStock"
                    control={
                      <PrimaryCheckbox
                        checked={filtersInUseState.Level0.includeOutOfStock}
                        onChange={(e: any) => {
                          handleChange(e, 0, 'includeOutOfStock');
                        }}
                        name="includeOutOfStock"
                        color="primary"
                      />
                    }
                    label={I18n?.includeOutOfStock?.i18n_value || 'Include Out of Stock'}
                    aria-label={I18n?.includeOutOfStock?.i18n_value || 'Include Out of Stock'}
                  />
                )}
                {showYourSpecialFilter() && (
                  <FormControlLabel
                    value="specialPrice"
                    data-id="specialPrice"
                    control={
                      <PrimaryCheckbox
                        checked={filtersInUseState.Level0.specialPrice}
                        onChange={(e: any) => {
                          handleChange(e, 0, 'specialPrice');
                        }}
                        name="specialPrice"
                        color="primary"
                      />
                    }
                    label={
                      <span className="grid-x special-price">
                        <span>{I18n?.specialPrice?.i18n_value || 'Your Specials'}</span>
                        <Star />
                      </span>
                    }
                    aria-label={I18n?.specialPrice?.i18n_value || 'Special Price'}
                  />
                )}
                {/* TODO: add warehouse filter */}
                {menuDataState && (
                  <WarehouseFilter
                    filtersInUseState={filtersInUseState}
                    isPxnr={pxnSettings?.isPxnr}
                    selectParticipant={selectParticipantAndSystemState}
                    pxnWarehousesObj={pxnWarehousesObj}
                    curTenantSettings={curTenantSettings}
                    handleWarehouseChange={handleChange}
                    itemCounts={itemCounts}
                    menuDataState={menuDataState}
                    stockListSettings={selectedStockListSettings}
                    participantsStocklistSettings={stockListSettings}
                    openWarehouseGroupDescriptionDialog={openWarehouseGroupDescriptionDialog}
                  />
                )}
                {menuDataState && (
                  <CategoryFilter
                    filtersInUseState={filtersInUseState}
                    menuDataState={menuDataState}
                    stockListSettings={selectedStockListSettings}
                    curTenantSettings={curTenantSettings}
                    handleChange={handleChange}
                  />
                )}
              </FormGroup>
              {[1, 2, 3].map((level: number) => {
                return (
                  <div key={'k_' + level}>
                    {level <= menuDataState?.noOfLevelsToBeDisplayed &&
                    selectedStockListSettings &&
                    selectedStockListSettings.searchLevels[filtersInUseState.Level0.category]
                      ? selectedStockListSettings.searchLevels[filtersInUseState.Level0.category][
                          level - 1
                        ].map((subCat: any) => {
                          const keys = menuDataState?.['currentSubCategoryValueArray'][subCat];
                          if (
                            selectedStockListSettings.searchHideSingleValueSelect?.[subCat] &&
                            keys.length <= 1
                          )
                            return '';
                          const subCatKey = 'k_' + level + '_' + subCat;
                          if (subCat === 'warehouse')
                            return <React.Fragment key={subCatKey}></React.Fragment>;
                          const source =
                            menuDataState?.['categories'][filtersInUseState.Level0.category][
                              subCat
                            ];
                          const i18nSubCat = selectedStockListSettings.attributeLabels[subCat]
                            ? selectedStockListSettings.attributeLabels[subCat]
                            : subCat.charAt(0).toUpperCase() + subCat.slice(1);
                          const subCatTitle =
                            I18n && I18n[i18nSubCat]
                              ? I18n[i18nSubCat]['i18n_value']
                              : I18n && I18n[subCat]
                              ? I18n[subCat]['i18n_value']
                              : getAttrLabel(curTenantSettings, subCat, i18nSubCat);
                          const canTheSubCategoryBeShown =
                            keys &&
                            keys.length > 0 &&
                            Object.keys(source).some((key: any) => source[key].itemsCount > 0);
                          // adding the concept of gradeGroup
                          let gradeGroups: gradeFilterGroups[] | undefined = undefined;
                          if (subCat === 'grade') {
                            gradeGroups = generateGradeGroup(curTenantSettings);
                          }
                          const showHeaderGradeIcon =
                            selectedStockListSettings.showGradeIcon && gradeGroups?.length === 1;
                          return (
                            <div
                              key={subCatKey}
                              className={`${canTheSubCategoryBeShown ? 'show' : 'hide'}`}
                            >
                              <div className="grid-x px-filter-group">
                                <h3>{subCatTitle}</h3>
                                {subCat === 'grade' && showHeaderGradeIcon && (
                                  <IconButton
                                    className="px-grade-info px-color-secondary"
                                    data-id="grade-info"
                                    onClick={() => openGradePopup()}
                                  >
                                    <InfoOutlinedIcon />
                                  </IconButton>
                                )}
                              </div>
                              <div
                                className={`px-stock-filter-group ${
                                  subCat === 'grade' ? 'px-grade-stock-filter-group' : ''
                                }`}
                                data-id={i18nSubCat}
                              >
                                {subCat === 'grade' && gradeGroups
                                  ? gradeGroups.map((gradeGroup, index) => {
                                      const validGradeFilters = gradeGroup.grades.filter(
                                        (grade) =>
                                          grade in source && source[grade]?.itemsCount !== 0
                                      );
                                      return (
                                        <div
                                          key={index}
                                          className="px-grade-group px-grade-margin-top "
                                        >
                                          {validGradeFilters.length > 0 && gradeGroup.header && (
                                            <div className="px-grade-header px-grade-margin-top">
                                              <div
                                                className="px-filter-grade-sub-text"
                                                dangerouslySetInnerHTML={{
                                                  __html: sanitizeHTML(gradeGroup.header),
                                                }}
                                              ></div>
                                              {(gradeGroups?.length || 0) > 1 && (
                                                <IconButton
                                                  className="px-grade-info px-color-secondary"
                                                  data-id="grade-info"
                                                  onClick={() =>
                                                    openGradePopup(gradeGroup.grades[0])
                                                  }
                                                >
                                                  <InfoOutlinedIcon />
                                                </IconButton>
                                              )}
                                            </div>
                                          )}
                                          {validGradeFilters.map((grade) => {
                                            if (!source[grade]) return null;
                                            const name = `k_${level}_${subCat}_${grade}`;
                                            const keyTitle = getKeyTitle(
                                              curTenantSettings,
                                              subCat,
                                              grade
                                            );
                                            //
                                            const curLevel =
                                              filtersInUseState[
                                                `Level${level}` as FilterInUseLevelType
                                              ] || {};
                                            return (
                                              <StockListFilterItems
                                                key={`sub_${name}`}
                                                level={level}
                                                subCat={subCat}
                                                value={grade}
                                                itemCount={source[grade].itemsCount}
                                                filterName={name}
                                                curLevel={curLevel}
                                                label={keyTitle}
                                                handleChange={handleChange}
                                              />
                                            );
                                          })}
                                          {gradeGroup.footer && (
                                            <div
                                              className="px-grade-margin-vertical px-filter-grade-sub-text"
                                              dangerouslySetInnerHTML={{
                                                __html: sanitizeHTML(gradeGroup.footer),
                                              }}
                                            ></div>
                                          )}
                                        </div>
                                      );
                                    })
                                  : keys &&
                                    keys
                                      .filter((key: any) =>
                                        level > 1 ? source[key].itemsCount !== 0 : true
                                      )
                                      .map((key: any) => {
                                        const name = `k_${level}_${subCat}_${key}`;
                                        const keyTitle = getKeyTitle(
                                          selectedStockListSettings,
                                          subCat,
                                          key
                                        );
                                        const curLevel =
                                          filtersInUseState[
                                            `Level${level}` as FilterInUseLevelType
                                          ] || {};
                                        return (
                                          <StockListFilterItems
                                            key={`sub_${name}`}
                                            level={level}
                                            subCat={subCat}
                                            value={key}
                                            itemCount={source[key].itemsCount}
                                            filterName={name}
                                            curLevel={curLevel}
                                            label={keyTitle}
                                            handleChange={handleChange}
                                          />
                                        );
                                      })}
                              </div>
                            </div>
                          );
                        })
                      : null}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="cell auto px-stock-list-items">
            {readyToShowFilterCounts && (
              <>
                <div className="cell small-12 margin-bottom-1" data-id="filterContainerBox">
                  {renderChips}
                </div>
                <div
                  className="cell small-12 px-items-count px-text-description"
                  style={{
                    display: loadingState === 99 || isReloadRef.current ? 'initial' : 'none',
                  }}
                >
                  <span className="small" data-id="totalItems">
                    <span>{I18n?.item?.i18n_value_plural || 'Items'}</span>
                    <span>: </span>
                    <span>{menuDataState?._totals?.itemsCount}</span>
                  </span>
                  <span className="small" data-id="totalQuantity">
                    <span>{I18n?.quantity?.i18n_value || 'Quantity'}</span>
                    <span>: </span>
                    <span>{menuDataState?._totals?.quantity}</span>
                    <span>{menuDataState?.isNetQuantityToBeMasked ? '+' : ''}</span>
                  </span>
                </div>
              </>
            )}
            <div className="cell small-12 px-items" data-id="itemGroups">
              <StockList
                isPxnr={!!pxnSettings?.isPxnr}
                isPxns={
                  !!pxnWarehousesObj?.[filtersInUseState?.Level0?.warehouseGroup]?.isPxns &&
                  !pxnWarehousesObj?.[filtersInUseState?.Level0?.warehouseGroup]?.isPxnr
                }
                itemCounts={itemCounts}
                menuDataState={menuDataState}
                filtersInUseState={filtersInUseState}
                selectedPreferencesOptions={selectedPreferencesOptions}
                updateSelectedPreferencesOptions={updateSelectedPreferencesOptions}
                pxnSettings={pxnSettings}
                pxnWarehousesObj={pxnWarehousesObj}
                pageDataState={pageDataState}
                stockListSettings={selectedStockListSettings}
                selectedCategory={filtersInUseState.Level0.category}
                openGradePopup={openGradePopup}
                setLoading={setLoading}
                openWarehouseGroupDescriptionDialog={openWarehouseGroupDescriptionDialog}
              />
              {readyToShowFilterCounts && (
                <>
                  <Button
                    onClick={goToTop}
                    color="primary"
                    innerRef={topOfResultsRef}
                    className="padding-0 margin-top-2"
                  >
                    <NavigationIcon className="vertical-middle margin-right-1" />
                    <Typography variant="h6" className="inline-block padding-top-3px">
                      {I18n?.topOfResults?.i18n_value || 'Top of Results'}
                    </Typography>
                  </Button>
                  <div className="cell small-12 margin-top-2">
                    <Pagination
                      count={Math.ceil(filteredItemsState.length / groupsPerPage)}
                      page={currentPage}
                      onChange={currentPageChange}
                      size="large"
                      showFirstButton
                      showLastButton
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          {/* <div className="cell small-12 large-auto margin-vertical-3">
              {I18n?.noItemsFound?.i18n_value || 'No items found!'}
            </div> */}
        </div>
      </div>
      {openDifferentView && (
        <React.Suspense fallback={<Progress />}>
          <ReviewStockImport
            setOpenDifferentView={setOpenDifferentView}
            setLoading={setLoading}
            data={reviewImportData}
            warehousesObj={pxnWarehousesObj}
            stockListSettings={selectedStockListSettings}
            curTenantSettings={curTenantSettings}
            isPxns={
              !!pxnWarehousesObj?.[filtersInUseState?.Level0?.warehouseGroup]?.isPxns &&
              !pxnWarehousesObj?.[filtersInUseState?.Level0?.warehouseGroup]?.isPxnr
            }
          />
        </React.Suspense>
      )}
      <BulkOfferImportDialog
        open={popupState['import']}
        ref={importDialogRef}
        stockListSettings={selectedStockListSettings}
        handleClose={(e: any) => {
          setPopupState({ ...popupState, import: false });
          setIsImportingDialog(false);
        }}
        handleUpload={handleUpload}
        isLoadingDialog={isImportLoading}
        isPxns={
          !!pxnWarehousesObj?.[filtersInUseState?.Level0?.warehouseGroup]?.isPxns &&
          !pxnWarehousesObj?.[filtersInUseState?.Level0?.warehouseGroup]?.isPxnr
        }
      />
      {popupState['dailyReport'] && (
        <DailyReportDialog
          isDialogOpen={true}
          closeDialog={(e: any) => setPopupState({ ...popupState, dailyReport: false })}
          onDailyReportOk={onDailyReportOk}
        />
      )}
      <StockListTutorialDialog
        isDialogOpen={popupState['tutorial']}
        url={configState.stockListTutorialUrl}
        closeDialog={(e: any) => setPopupState({ ...popupState, tutorial: false })}
      />
      {!!curTenantSettings.gradeLabelDescriptions?.length && (
        <GradePopup
          isDialogOpen={popupState['grades']}
          closeDialog={(e: any) => {
            setSelectedGrade(undefined);
            setPopupState({ ...popupState, grades: false });
          }}
          grade={selecetedGrade}
          gradesList={curTenantSettings.gradeLabelDescriptions}
          pxnParticipantId={filtersInUseState.Level0.warehouseGroup}
          stockListItems={stockListResults}
        />
      )}
      {popupState['warehouseGroupDescription'] && selectedWarehouseGroup && (
        <React.Suspense fallback={<Progress />}>
          <WarehouseGroupDescriptionDialog
            pxnWarehousesObj={pxnWarehousesObj}
            closeDialog={closeWarehouseGroupDescriptionDialog}
            acknowledgeDialog={acknowledgeWarehouseGroupDescriptionDialog}
            {...selectedWarehouseGroup}
          />
        </React.Suspense>
      )}
      {accountSetupPopup && (
        <AccountSetupPopup
          isDialogOpen={accountSetupPopup}
          closeDialog={() => setAccountSetupPopup(false)}
        />
      )}
    </>
  ) : (
    <div className="margin-top-2">
      <Progress />
    </div>
  );
}

function mapStateToProps(state: any) {
  return {
    stockListSettings: state.stockGroupState.settings,
    pxnWarehousesObj: state.stockGroupState.pxnParticipantWarehousesObj,
    pxnSettings: state.stockGroupState.pxnSettings,
    authorities: state.userState.authorities,
    urlState: state.urlState,
    enabledTags: state.userState.userInfo?.enabledTags,
    setupCompleted: state.userState.userInfo?.setupCompleted,
    configState: state.configState,
    cartNumber: Object.keys(state.stockGroupState.offers || {}).length,
    alertsBadgeNumber: Object.keys(state.stockGroupState.alerts || {}).length,
  };
}

export default connect(mapStateToProps)(Stock);
