import React, { useContext, useEffect } from 'react';
import Amplify, { Auth } from 'aws-amplify';
import { useDispatch } from 'react-redux';
import getAwsconfig from '../aws-exports';
import { configSet, deactivateTenant } from '../redux/actions/configActions';
import { setUserAndFetchAuthorities, userStateSet } from '../redux/actions/userActions';
import { eMessageType } from '../types/IMessageType';
import { flagsSet } from '../redux/actions/flagsActions';
import { getI18n, I18nSetterContext } from '../I18n';

export const getConfigFromLocalStorage = () => {
  return JSON.parse(localStorage.getItem('PxConfig') || '{}');
};
const setConfigToLocalStorage = async (config: any) => {
  localStorage.setItem('PxConfig', JSON.stringify(config));
};

const getPublicDataEndpoint = (env: any) => {
  let publicDataEndpoint;
  publicDataEndpoint = process.env.REACT_APP_API_URL + '/px-api-gateway';
  if (!process.env.REACT_APP_API_URL)
    switch (env) {
      case 'test':
        publicDataEndpoint =
          'https://s4z4motqq3.execute-api.us-east-1.amazonaws.com/Integration/px-api-gateway';
        break;
      case 'prod':
        publicDataEndpoint =
          'https://qkexusfqqf.execute-api.us-east-1.amazonaws.com/Integration/px-api-gateway';
        break;
      case 'release':
        publicDataEndpoint =
          'https://94o107xk88.execute-api.us-east-1.amazonaws.com/Integration/px-api-gateway';
        break;
      default:
      // publicDataEndpoint = process.env.REACT_APP_API_URL + '/px-api-gateway';
    }
  return publicDataEndpoint;
};

const fetchConfig = async (env: any) => {
  const publicDataEndpoint = getPublicDataEndpoint(env) + '/saas-config';
  const res = await fetch(publicDataEndpoint, {
    method: 'GET',
  });
  const data = await res.json();
  if (data && data.status === 200) return data.results;
  else return {};
};

const setConfigAndStyle = (dispatch: any, config: any) => {
  const settings = config?.dark_mode_features;
  const style = document.createElement('style');
  style.innerHTML = settings;
  document.documentElement.appendChild(style);
  console.log(getAwsconfig(config));
  Amplify.configure(getAwsconfig(config));
  dispatch(configSet(config));
};

const setCurrentUserAuthority = async (dispatch: any) => {
  try {
    let currentUser = await Auth.currentAuthenticatedUser();
    dispatch(setUserAndFetchAuthorities(currentUser));
  } catch (error) {
    dispatch(userStateSet({ isSignedIn: false }));
  }
};

const tasksAfterConfigFetchSuccessfully = async (
  dispatch: any,
  config: any,
  setI18n: (i18n: any) => void
) => {
  try {
    setConfigAndStyle(dispatch, config);
    getI18n().then((value) => setI18n(value));
    setCurrentUserAuthority(dispatch);
  } catch (error: any) {
    console.log(error);
    dispatch(userStateSet({ isSignedIn: false }));
  }
};

export const getConfig = async (dispatch: any, setI18n: (i18n: any) => void) => {
  // fetch in background and update local storage
  const remoteConfig = await fetchConfig(process.env.REACT_APP_STAGE);
  //if the tenant is deactivated - config will have key deactivated
  if (remoteConfig.deactivated) {
    dispatch(deactivateTenant(remoteConfig));
    setConfigToLocalStorage(remoteConfig);
    return;
  }

  const parsedConfig = await getConfigFromLocalStorage();
  // always set remote config, but only when it doesn't exist, or versions do not match local
  if (!parsedConfig.appVersion || parsedConfig.appVersion !== remoteConfig.appVersion) {
    setConfigToLocalStorage(remoteConfig);
    let isSignedIn = false;
    if (parsedConfig.appVersion) {
      try {
        await Auth.currentAuthenticatedUser();
        isSignedIn = true;
      } catch (error) {}
    }
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    parsedConfig.appVersion &&
      isSignedIn &&
      dispatch(
        flagsSet({
          message: (
            <>
              A Newer App Version is Available{' '}
              <a
                onClick={function () {
                  window.location.reload();
                }}
                href="##"
                className="px-underline px-color-text-on-snackbar"
              >
                REFRESH APP
              </a>
            </>
          ),
          type: 'SNACKBAR',
          severity: eMessageType.success,
          open: true,
        })
      );
    ['userInfo', 'PxSideMenuItems', 'PxUserAuthorities', 'PxI18n'].forEach((key) =>
      localStorage.removeItem(key)
    );
    //currency precision set to local storage
    const currencyPrecision = localStorage.getItem('PxCurrencyPrecision');
    if (!!remoteConfig.currencyPrecision && currencyPrecision !== remoteConfig.currencyPrecision) {
      localStorage.setItem('PxCurrencyPrecision', remoteConfig.currencyPrecision);
    }
    if (remoteConfig.currency) localStorage.setItem('PxCurrencySymbol', remoteConfig.currency);

    await tasksAfterConfigFetchSuccessfully(dispatch, remoteConfig, setI18n);
  }
};

export const resetPassword = async (body: any, tenant_id: string) => {
  const publicDataEndpoint =
    getPublicDataEndpoint(process.env.REACT_APP_STAGE) + '/saas-login/reset-password';
  const res = await fetch(publicDataEndpoint, {
    method: 'POST',
    body,
    headers: { 'Content-Type': 'application/json', tenant_id },
  });
  if (res && res.status === 200) return res.json();
  return {};
};

export const getSalesRep = async (tenant_id: string) => {
  const publicDataEndpoint =
    getPublicDataEndpoint(process.env.REACT_APP_STAGE) + '/buyer-onboarding/sales-reps';
  const res = await fetch(publicDataEndpoint, { method: 'GET', headers: { tenant_id } });
  if (res && res.status === 200) return res.json();
  return [];
};

const useFetchConfig = () => {
  const dispatch = useDispatch();
  const setI18n = useContext(I18nSetterContext);

  useEffect(() => {
    async function fetchConfigAndUser() {
      const config = await getConfigFromLocalStorage();
      setTimeout(() => {
        getConfig(dispatch, setI18n);
      }, 0);
      if (!Object.keys(config).length) return;
      await tasksAfterConfigFetchSuccessfully(dispatch, config, setI18n);
    }
    fetchConfigAndUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useFetchConfig;
