import { API, Auth } from 'aws-amplify';
import { IMessageType, eMessageType } from '../types/IMessageType';
import { downloadFile, sanitizeString } from '../_lib/lib';
import { I18nContext } from '../I18n';
import { useContext } from 'react';
import { useErrorHandlerContext } from '../components/ErrorHandlers/ErrorHandler';
import { logOutTheUser } from '../_lib/util';

export type fetchUrlSignature = (
  httpMethod: string,
  path: string,
  additionalParams: any,
  customErrorMessage?: string,
  sendErrorCode?: boolean,
  manualCatchHandle?: boolean
) => Promise<any>;
export type dynamicFetchUrlSignature = (
  apiNameKey: string,
  httpMethod: string,
  path: string,
  additionalParams: any,
  customErrorMessage?: string,
  sendErrorCode?: boolean,
  manualCatchHandle?: boolean
) => Promise<any>;
type TunauthorizedUserErrorHandler = {
  message: string;
  messageCode: string;
  statusCode: number;
};

const useDataService = () => {
  const {
    openSnackBar,
    closeSnackBar,
  }: {
    openSnackBar(message: any, type: IMessageType): any;
    closeSnackBar(): void;
  } = useErrorHandlerContext();

  const pxConfig = localStorage.getItem('PxConfig') || '{"tenantApiName":"tenantSpecificEndpoint"}';
  const configJSON = JSON.parse(pxConfig);
  const modules =
    typeof configJSON.modules === 'string' ? JSON.parse(configJSON.modules) : configJSON.modules;
  let apiName = modules?.phonex_saas_mfe_tenant?.tenantApiName,
    tenantEndPoint = modules?.phonex_saas_mfe_tenant?.tenantApiEndPoint;
  if (!apiName && configJSON?.tenantApiName) apiName = configJSON.tenantApiName;
  if (!tenantEndPoint && configJSON?.tenantApiEndPoint)
    tenantEndPoint = configJSON.tenantApiEndPoint;

  const I18n = useContext(I18nContext);

  const fetchUrl: fetchUrlSignature = async (
    httpMethod: string,
    path: string,
    additionalParams: any,
    customErrorMessage?: string,
    sendErrorCode?: boolean,
    manualCatchHandle = false
  ) => {
    let result: any;
    try {
      switch (httpMethod.toUpperCase()) {
        case 'GET': {
          result = await API.get(apiName, path, additionalParams);
          break;
        }
        case 'POST': {
          result = await API.post(apiName, path, additionalParams);
          break;
        }
        case 'PUT': {
          result = await API.put(apiName, path, additionalParams);
          break;
        }
        case 'PATCH': {
          result = await API.patch(apiName, path, additionalParams);
          break;
        }
        case 'DELETE': {
          result = await API.del(apiName, path, additionalParams);
          break;
        }
        default:
          return null;
      }
    } catch (error: any) {
      return await handleError(error, customErrorMessage, sendErrorCode, manualCatchHandle);
    }

    //if (!result) openSnackBar('Internal Server Error', 'error');

    return result;
  };

  const dynamicFetchUrl: dynamicFetchUrlSignature = async (
    apiName: string,
    httpMethod: string,
    path: string,
    additionalParams: any,
    customErrorMessage?: string,
    sendErrorCode?: boolean,
    manualCatchHandle = false
  ) => {
    let result: any;
    try {
      switch (httpMethod.toUpperCase()) {
        case 'GET': {
          result = await API.get(apiName, path, additionalParams);
          break;
        }
        case 'POST': {
          result = await API.post(apiName, path, additionalParams);
          break;
        }
        case 'PUT': {
          result = await API.put(apiName, path, additionalParams);
          break;
        }
        case 'PATCH': {
          result = await API.patch(apiName, path, additionalParams);
          break;
        }
        case 'DELETE': {
          result = await API.del(apiName, path, additionalParams);
          break;
        }
        default:
          return null;
      }
    } catch (error: any) {
      return await handleError(error, customErrorMessage, sendErrorCode, manualCatchHandle);
    }

    //if (!result) openSnackBar('Internal Server Error', 'error');

    return result;
  };

  interface IExportData {
    url: string;
    method?: string;
    fileName?: string;
    body?: any;
    headers?: any;
  }

  const exportData = async ({ url, method, fileName, body, headers }: IExportData) => {
    try {
      const file = await fetchUrl(method || 'get', url, {
        body: body || {},
        headers: {
          accept: 'application/excel',
          ...headers,
        },
        responseType: 'blob',
        response: true,
      });
      return await downloadFile(file, fileName);
    } catch (error: any) {
      // unable to download the file
      openSnackBar(error.message, eMessageType.error);
      return await handleError(error);
    }
  };

  interface IFileUploadHeader {
    header: string;
    value: string;
  }

  interface fileUploadAdditionalParams {
    fileName: string;
  }

  const fileUpload = (
    file: any,
    path: string,
    headers?: Array<IFileUploadHeader>,
    contentType?: string,
    absolute?: boolean,
    additionalParams?: fileUploadAdditionalParams
  ) => {
    return new Promise(async (resolve: any, reject: any) => {
      try {
        var xmlHttpRequest = new XMLHttpRequest();
        xmlHttpRequest.open('POST', absolute ? path : tenantEndPoint + path, true);
        let updatedContentType = undefined;
        if (!absolute && !contentType)
          updatedContentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        else {
          updatedContentType = contentType;
        }
        if (updatedContentType) xmlHttpRequest.setRequestHeader('Content-Type', updatedContentType);

        xmlHttpRequest.setRequestHeader(
          'Authorization',
          `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        );
        const fileName = additionalParams?.fileName ?? sanitizeString(file.name);
        xmlHttpRequest.setRequestHeader('file-name', fileName);
        if (headers) {
          headers.forEach((val) => {
            xmlHttpRequest.setRequestHeader(val.header, val.value);
          });
        }
        xmlHttpRequest.setRequestHeader('Accept', 'application/json');
        xmlHttpRequest.onreadystatechange = function () {
          // In local files, status is 0 upon success in Mozilla Firefox
          if (xmlHttpRequest.readyState === XMLHttpRequest.DONE) {
            if (xmlHttpRequest.status !== 200) {
              reject(
                JSON.parse(xmlHttpRequest.response.length > 0 ? xmlHttpRequest.response : '{}')
              );
            }
            resolve(
              JSON.parse(xmlHttpRequest.response.length > 0 ? xmlHttpRequest.response : '{}')
            );
          }
        };
        xmlHttpRequest.send(file);
      } catch (error: any) {
        reject(absolute ? error : error.message);
      }
    });
  };

  const handleError = async (
    error: any,
    customErrorMessage?: string,
    sendErrorCode?: boolean,
    manualCatchHandle = false
  ) => {
    const { response } = error;
    const erroResponseCode = response?.data.statusCode;
    let errorMessage =
        response?.data?.message || error?.message || customErrorMessage || 'Internal Server Error',
      status = 0,
      messageCode = 'INTERNAL_SERVER_ERROR',
      traceId = '';

    if (erroResponseCode === 403) return await unauthorizedUserErrorHandler(response?.data, I18n);

    if (response) {
      status = response.status;
      const isUnauthorized = status === 401 || response.data.statusCode === 401;
      traceId = response.headers['x-amzn-trace-id'] || '';
      messageCode = isUnauthorized ? 'UNAUTHORIZED' : response.data.messageCode || messageCode;
      if (isUnauthorized) logOutTheUser('Session Expired', 'error');
      if (response.data?.messageCode) {
        const msg = I18n._format(
          I18n[response.data.messageCode]?.i18n_value || response.data.message,
          Array.isArray(response.data.params) ? response.data.params : []
        );
        if (msg) errorMessage = msg;
      }
    }

    if (response?.status === 422) {
      if (response?.data.status === 'FAIL') errorMessage = response?.data?.errors[0]?.messageCode;
      else errorMessage = response?.data?.errors[0]?.messageDescription ?? error.message;
    }

    try {
      await Auth.currentAuthenticatedUser();
    } catch (error) {
      return logOutTheUser(errorMessage, 'error');
    }

    if (!manualCatchHandle) openSnackBar(errorMessage, eMessageType.error);
    if (sendErrorCode) errorMessage = response.data?.messageCode || 'INVALID_REQUEST';
    // eslint-disable-next-line no-throw-literal
    throw {
      name: 'NetworkError',
      message: errorMessage,
      errorBody: response ? response?.data : null,
      status: status,
      messageCode: messageCode,
      traceId: traceId,
    };
  };

  return { fetchUrl, openSnackBar, closeSnackBar, fileUpload, exportData, dynamicFetchUrl };
};

export const unauthorizedUserErrorHandler = async (
  resData: TunauthorizedUserErrorHandler,
  I18n?: any
) => {
  if (resData.messageCode === 'userDisabled' || resData.messageCode === 'GENERIC_CUSTOMER') {
    const userMessages = {
      contactSupportMessage:
        I18n?.accountDeactivatedContactSupport?.i18n_value ||
        'This account has been deactivated. Please contact customer support at {${1}}',
      contactSalesRepMessage:
        I18n?.accountDeactivatedContactSalesRep?.i18n_value ||
        'This account has been deactivated. Please contact your sales rep.',
    };
    const supportNumber = JSON.parse(localStorage.getItem('PxConfig') || '{}')?.clientSupportNumber;
    const i18nDisableUserMessage = I18n?._format(
      supportNumber ? userMessages.contactSupportMessage : userMessages.contactSalesRepMessage,
      [supportNumber]
    );

    await logOutTheUser(
      i18nDisableUserMessage,
      'error',
      supportNumber ? 'disabledUser_template' : undefined
    );
  } else {
    const resDataStr = resData && JSON.stringify(resData);
    await logOutTheUser('', 'error', undefined, !!resDataStr, () => {
      if (resDataStr) {
        localStorage.clear();
        localStorage.setItem('pxUnauthorizedUserMsg', resDataStr);
      }
    });
  }
  // eslint-disable-next-line no-throw-literal
  throw {
    ...resData,
    status: resData.statusCode,
  };
};

export default useDataService;
